import style from "./Settings.module.css"

const Settings = () => {
	return (
		<div className={style.settings}>
	Settings
		</div>

	)
}

export default Settings;