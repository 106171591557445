import style from "./News.module.css"

const News = () => {
	return (
		<div className={style.news}>
			News
		</div>

	)
}

export default News;